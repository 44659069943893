export const CHANGE_AVAILABILITY_FILTER = 'CHANGE_AVAILABILITY_FILTER'
export const CHANGE_DISTANCE_FILTER = 'CHANGE_DISTANCE_FILTER'
export const CHANGE_CONNECTOR_FILTER = 'CHANGE_CONNECTOR_FILTER'
export const CHANGE_SEARCH_FILTER = 'CHANGE_SEARCH_FILTER'
export const FETCH_LIST_LOCATIONS = 'FETCH_LIST_LOCATIONS'
export const FETCH_MAP_LOCATIONS = 'FETCH_MAP_LOCATIONS'
export const FETCH_LOCATION_DETAIL = 'FETCH_LOCATION_DETAIL'
export const UPLOAD_PROFILE_PHOTO = 'UPLOAD_PROFILE_PHOTO'
export const CHARGING_SESSION = 'CHARGING_SESSION'
export const UPDATE_CHARGING_SESSION_CONSUMPTION = 'UPDATE_CHARGING_SESSION_CONSUMPTION'
export const FETCH_CHARGING_SESSION = 'FETCH_CHARGING_SESSION'
export const START_CHARGING = 'START_CHARGING'
export const STOP_CHARGING = 'STOP_CHARGING'
export const SET_CAR = 'SET_CAR'
export const FETCH_CHARGING_HISTORY = 'FETCH_CHARGING_HISTORY'
export const CHARGING_HISTORY_FILTER = 'CHARGING_HISTORY_FILTER'
export const FETCH_CHARGER_PRICELISTS = 'FETCH_CHARGER_PRICELISTS'
export const FETCH_CHARGER_PRICELIST_DATA = 'FETCH_CHARGER_PRICELIST_DATA'
export const FETCH_PAYMENT_DETAILS = 'FETCH_PAYMENT_DETAILS'
export const EDIT_USER = 'EDIT_USER'
export const SET_PAYMENT_STATE = 'SET_PAYMENT_STATE'
export const FETCH_CHARGING_CONNECTOR = 'FETCH_CHARGING_CONNECTOR'
export const UPDATE_LOCATION_INFO = 'UPDATE_LOCATION_INFO'
export const CHANGE_SORT_FILTER = "CHANGE_SORT_FILTER"

export const UPDATE_CONNECTOR_STATUS_LOCATION_DETAIL = "UPDATE_CONNECTOR_STATUS_LOCATION_DETAIL"
export const UPDATE_CHARGING_SESSION_LOCATION_DETAIL = "UPDATE_CHARGING_SESSION_LOCATION_DETAIL"

export const MAP_TOKEN = 'MAP_TOKEN'
export const TENANT = 'TENANT'

