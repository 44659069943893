import { sendData, sendJson, patchProperty, getJson } from '../../shared/utils/api'
import { asyncActionCreator, clearAsync, valueHolderActionCreator } from '../../shared/redux/actions/common'
import { UPLOAD_PROFILE_PHOTO, EDIT_USER, FETCH_PAYMENT_DETAILS, SET_PAYMENT_STATE, TENANT } from '../const'

export const uploadProfilePhoto = (photo: File) => {
    return asyncActionCreator(UPLOAD_PROFILE_PHOTO, () => sendData(`users/image`, 'POST', photo))
}

// const changeUserErrorCodeMapping = [['2', 'users.emailAlreadyUsed']]

export type UserEditModel = {
    id: string
    firstName: string
    lastName: string
    phone: string
    carLicensePlate: string
    street: string
    city: string
    country: string
    zip: string
    companyName: string
    companyId: string
    taxId: string
    vatId: string
    rfid: string
    cardNumber: string
    cardType: string
    cardExpiration: string
    paymentRequestId: string
}

export const fetchUser = () => {
    return asyncActionCreator(EDIT_USER, () => getJson('Users/me'))
}

export const editUser = (user: UserEditModel) => {
    return asyncActionCreator(EDIT_USER, () =>
        sendJson(`users/${user.id}`, 'PATCH', [
            patchProperty('firstName', user.firstName),
            patchProperty('lastName', user.lastName),
            patchProperty('phone', user.phone),
            patchProperty('carLicensePlate', user.carLicensePlate),
            patchProperty('street', user.street),
            patchProperty('city', user.city),
            patchProperty('country', user.country),
            patchProperty('zip', user.zip),
            patchProperty('companyName', user.companyName),
            patchProperty('companyId', user.companyId),
            patchProperty('taxId', user.taxId),
            patchProperty('vatId', user.vatId),
            patchProperty('rfid', user.rfid),
            patchProperty('cardNumber', user.cardNumber),
            patchProperty('cardType', user.cardType),
            patchProperty('cardExpiration', user.cardExpiration),
            patchProperty('paymentRequestId', user.paymentRequestId),
        ])
    )
}

export const cardRegister = (userId: String, params: URLSearchParams) => {
    var trustPayWebRegistrationNotification = {
        "UserId": userId,
        "AccountId": params.get('AccountId'),
        "Type": params.get('Type'),
        "Amount": params.get('Amount'),
        "Currency": params.get('Currency'),
        "PaymentRequestId": params.get('PaymentRequestId'),
        "CardId": params.get('CardId'),
        "CardMask": params.get('CardMask'),
        "CardExpiration": params.get('CardExpiration'),
        "AuthNumber": params.get('AuthNumber'),
        "Signature": params.get('Signature'),
        "ResultCode": params.get('ResultCode'),
        "Reference": params.get('Reference'),
    }
    return asyncActionCreator(EDIT_USER, () =>
        sendJson("Pay/cardRegister", "POST", trustPayWebRegistrationNotification)
    )
}

export const clearEditState = () => {
    return clearAsync(EDIT_USER)
}

export const fetchPaymentDetails = (userId: string, amount: string, currency: string, paymentType: number) => {
    const url = `Pay/trustPay?userId=${userId}&amount=${amount}&currency=${currency}&paymentType=${paymentType}`
    return asyncActionCreator(FETCH_PAYMENT_DETAILS, () => getJson(url))
}

export const setPaymentState = (state: number) => {
    return valueHolderActionCreator(SET_PAYMENT_STATE, state)
}

export const clearPaymentState = () => {
    return valueHolderActionCreator(SET_PAYMENT_STATE, null)
}
export const setTenant = (tenant: any) => {
    return valueHolderActionCreator(TENANT, tenant);
  };