import { chargingSessionReducer } from './charging'
import { locationMapReducer } from './locationsMap'
import { locationLisReducer } from './locationsList'
import { locationDetailReducer } from './locationDetail'
import { combineReducers } from 'redux'
import {
    errorReducer,
    valueHolderReducer,
    asyncPagedActionReducer,
    asyncActionReducer,
} from './../../shared/redux/reducers/common'
import { LOGIN, SET_PAYMENT_SETTINGS } from '../../shared/redux/const'
import {
    CHANGE_SEARCH_FILTER,
    CHANGE_AVAILABILITY_FILTER,
    CHANGE_DISTANCE_FILTER,
    CHANGE_CONNECTOR_FILTER,
    FETCH_LIST_LOCATIONS,
    FETCH_MAP_LOCATIONS,
    UPLOAD_PROFILE_PHOTO,
    FETCH_LOCATION_DETAIL,
    MAP_TOKEN,
    CHARGING_SESSION,
    START_CHARGING,
    SET_CAR,
    STOP_CHARGING,
    FETCH_CHARGING_HISTORY,
    CHARGING_HISTORY_FILTER,
    FETCH_CHARGER_PRICELISTS,
    FETCH_CHARGER_PRICELIST_DATA,
    EDIT_USER,
    FETCH_PAYMENT_DETAILS,
    SET_PAYMENT_STATE,
    CHANGE_SORT_FILTER,
    TENANT
} from '../const'
import { userLoginReducer } from './user'

export default combineReducers({
    user: combineReducers({
        login: valueHolderReducer(
            LOGIN,
            { isLoggedIn: false, isAnonymous: false, user: null },
            userLoginReducer
        ),
        uploadProfilePhoto: asyncActionReducer(UPLOAD_PROFILE_PHOTO),
        edit: asyncActionReducer(EDIT_USER, null),
    }),
    tenant: valueHolderReducer(TENANT),
    filter: combineReducers({
        search: valueHolderReducer(CHANGE_SEARCH_FILTER),
        availability: valueHolderReducer(CHANGE_AVAILABILITY_FILTER, false),
        distance: valueHolderReducer(CHANGE_DISTANCE_FILTER, {
            km: undefined,
            location: undefined,
        }),
        connector: valueHolderReducer(CHANGE_CONNECTOR_FILTER, undefined),
        sortByDistance: valueHolderReducer(CHANGE_SORT_FILTER, true)
    }),
    locationsList: asyncPagedActionReducer(FETCH_LIST_LOCATIONS, locationLisReducer),
    locationsMap: asyncActionReducer(FETCH_MAP_LOCATIONS, [], locationMapReducer),
    locationDetail: asyncActionReducer(FETCH_LOCATION_DETAIL, undefined, locationDetailReducer),
    mapToken: valueHolderReducer(MAP_TOKEN),
    error: errorReducer(),
    chargingSession: valueHolderReducer(CHARGING_SESSION, undefined, chargingSessionReducer),
    chargerPricelists: asyncActionReducer(FETCH_CHARGER_PRICELISTS),
    chargerPricelistsData: asyncActionReducer(FETCH_CHARGER_PRICELIST_DATA),
    startCharging: asyncActionReducer(START_CHARGING),
    stopCharging: asyncActionReducer(STOP_CHARGING),
    car: valueHolderReducer(SET_CAR),
    chargingHistory: asyncPagedActionReducer(FETCH_CHARGING_HISTORY),
    chargingHistoryFilter: valueHolderReducer(CHARGING_HISTORY_FILTER, 0),
    payment: combineReducers({
        paymentDetails: asyncActionReducer(FETCH_PAYMENT_DETAILS),
        paymentState: valueHolderReducer(SET_PAYMENT_STATE, null)
    }),
    paymentSettings: valueHolderReducer(SET_PAYMENT_SETTINGS)
})
