import { UserModel } from "./user";
import { Image } from "./common";
import { Charger, ChargerConnector } from "./charger";

export type LocationInfo = {
  id: string;
  latitude: number;
  longitude: number;
  chargerCount: number;
  chargersInUseCount: number;
  availableConnectors: number;
  workingConnectors: number;
  connectedChargers: Array<string>;
  connectorCount: number; // all connectors
};

export type LocationModel = {
	name: string | undefined;
	description: string | undefined;
	address: string | undefined; //TODO vyhodit po editacii DB
	latitude: number;
	longitude: number;
	totalPower: string | undefined;
	lowTariffId?: string;
	city: string | undefined;
	street: string | undefined;
	postalCode: string | undefined;
	houseNum: string | undefined;
  phoneNumber: string | undefined;
	hidden: boolean;
  includeInHubject: boolean;
  tenantId: string | undefined;
};

export type Location = LocationModel & {
  id: string | undefined;
};

export type LocationListItem = Location & {
  chargerCount: number;
  chargersInUseCount: number;
  chargingSessionsCount: number;
  chargingSessionsInProgressCount: number;
  distanceInMeters: number;
  availableConnectors: number;
  workingConnectors: number;
  connectedChargers: Array<string>;
  connectorCount: number; // all connectors
  images: Image[];
};

export type LocationWithChargers = LocationListItem & {
  chargers: Charger[];
};

export enum ChargingSessionState {
  Pending,
  InProgress,
  Parking,
  Finished,
  FailedToSetChargingProfile,
}

export type ChargingSession = {
  id: string;
  startedAt: Date;
  endedAt: Date;
  startedParkingAt: Date;
  length: number;
  consumedWh: number;
  importedWh: number;
  offeredWh: number;
  price: number;
  user: UserModel;
  location: LocationListItem;
  charger: Charger;
  chargingConnector: ChargerConnector;
  chargingConnectorId: string;
  state: ChargingSessionState;
  hasInvoice: boolean;
  errorCause: string;
};
