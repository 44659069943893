import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setChargingSession, updateChargingSessionConsumption, getChargingSession } from './../redux/actions/charging'
import { setPaymentState } from './../redux/actions/users'
import { ChargingSession, ChargingSessionState } from './../shared/types/location'
import { getJson } from './../shared/utils/api'
import { config } from '../config'

export function getCurrentSession() {
    return getJson(`chargingSession/me?page=1&pageSize=1&Sort=endedAt desc`)
}

export function updateCurrentSession(dispatch: any, chargingSessions: ChargingSession[]) {
    const lastActiveSession = chargingSessions.find((x: ChargingSession) => x.endedAt == null)
    dispatch(setChargingSession(lastActiveSession))
}

var socket: WebSocket

export function closeWebSocket() {
    socket.close()
}

export function useSessionPeriodicUpdate(chargingSession: ChargingSession) {
    const dispatch = useDispatch()
    const chargerExternalId = chargingSession?.charger?.externalId
    const connectorExternalId = chargingSession?.chargingConnector?.externalId
    const chargingSessionState = useSelector((state: any) => state.chargingSession)

    const createSocket = (socketUrl: string) => {
        const setEndedSession = async (id: string) => {
            const result = await dispatch(getChargingSession(id))
            dispatch(setChargingSession(result))
        }

        socket = new WebSocket(socketUrl, ['ocpp1.6'])
        socket.onmessage = (event) => {
            const sessionState = JSON.parse(event.data)
            console.log(sessionState)
            switch (sessionState.State) {
                case ChargingSessionState.InProgress: {
                    /**
                     * https://app.asana.com/0/1199592801905492/1202319938804997
                     * filtrujem nulové hodnoty, na FE som to spravil kvôli tasku https://app.asana.com/0/1199592801905492/1200213269350687
                     */
                    if (
                        sessionState.ConsumedWh === 0 &&
                        sessionState.ImportedWh === 0 &&
                        sessionState.OfferedWh === 0
                    ) {
                        console.log('MeterValue received from Charger contains all values == 0, FE will not be updated')
                    } else {
                        dispatch(
                            updateChargingSessionConsumption(
                                sessionState.ConsumedWh,
                                sessionState.ImportedWh,
                                sessionState.OfferedWh
                            )
                        )
                    }
                    break
                }
                case ChargingSessionState.Parking: {
                    dispatch(setChargingSession({ ...chargingSessionState.value, state: sessionState.State }))
                    break
                }
                case ChargingSessionState.Finished: {
                    setEndedSession(chargingSessionState.value.id)
                    dispatch(setPaymentState(sessionState.PaymentResult))
                    socket.close()
                    break
                }
            }
        }
        return () => {}
    }

    useEffect(() => {
        if (chargerExternalId && connectorExternalId) {
            if (socket == null) {
                const socketUrl = `${config.websocket}/ocpp/client_${chargerExternalId}_${connectorExternalId}`
                createSocket(socketUrl)
            } else {
                const currSocketUrl = socket.url
                const newSocketUrl = `${config.websocket}/ocpp/client_${chargerExternalId}_${connectorExternalId}`
    
                if (currSocketUrl !== newSocketUrl) {
                    createSocket(newSocketUrl)
                }
            }
        }
    }, [dispatch, chargerExternalId, connectorExternalId, chargingSessionState])
}
