import React from 'react'
import { useTranslation } from 'react-i18next'
import { AsyncActionConfirmationDialog } from './asyncActionConfirmationDialog'

type DeleteConfirmationDialogProps = {
    open: boolean
    onConfirm: Function
    onCancel: Function
    inProgress: boolean
}

export function DeleteConfirmationDialog(props: DeleteConfirmationDialogProps) {
    const { t } = useTranslation()

    return (
        <AsyncActionConfirmationDialog
            {...props}
            title={t('common.deleteTitle')}
            content={t('common.deleteContent')}
            confirmLabel={t('common.delete')}
            cancelLabel={t('common.cancel')}
        />
    )
}
