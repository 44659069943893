import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import WatchLater from '@material-ui/icons/WatchLaterOutlined'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLocationDetail } from '../../redux/actions/locations'
import { LocationWithChargers } from '../../shared/types/location'
import { Image } from './../../shared/components/Image'
import { DetailHeader } from './DetailHeader'
import NavigationIcon from '../../static/icons/icon-outline-navigate-white.svg'

const useStyles = makeStyles((theme) => ({
    mainContainer: { zoom: '0.75' },
    locationPreviewContainer: {
        height: '466px',
        backgroundColor: 'black',
    },
    headerImageContainer: {
        height: '254px',
        background: 'transparent',
    },
    expandButton: {
        color: 'white',
        borderRadius: 0,
    },
    chargerImage: {
        position: 'absolute',
        height: '330px',
        width: '100%',
        top: '0',
        left: '0',
    },
    dataContainer: {
        backgroundColor: 'black',
        position: 'absolute',
        top: '254px',
        width: '100%',
        borderRadius: '20px 20px 0px 0px',
    },
    topDecoration: {
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rectangle: {
        display: 'inline-block',
        width: '75px',
        height: '4px',
        borderRadius: '2px',
        backgroundColor: '#323638',
    },
    headerContainer: {
        fontFamily: "'Play', sans-serif",
        fontSize: '32px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: 'white',
        marginTop: '15px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: '10px',
    },
    openTimeContainer: { color: '#32bd72', marginTop: '9px' },
    openTimeIconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '9px',
        width: '13px',
    },
    openTimeIcon: { width: '20px' },
    openTime: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    wrapper: {},
    wrapperInner: { marginLeft: '23px', marginRight: '23px' },
    expandContainer: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    controlPanelContainer: {
        marginTop: '24px',
        fontFamily: "'Play', sans-serif",
        fontSize: '20px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.6,
        letterSpacing: 'normal',
        color: 'white',
    },

    controlPanelLeftButton: {
        borderRadius: '14px',
        border: 'solid 1px #37c0ca',
        height: '56px',

        maxWidth: '159px',
    },
    navigate: { height: '56px', width: '159px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    navigateIconContainer: { display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '7px' },
    navigateIcon: { width: '20px', color: 'white', transform: 'scaleX(-1)' },
    navigateText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: "'Muli', sans-serif",
        fontSize: '12px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: '0.86px',
        color: 'white',
    },
}))

interface LocationPreviewProps {
    locationId: string
    toDetailClick?: any
    onBackOpen: string
}

export const LocationPreview: React.FC<LocationPreviewProps> = ({ locationId, toDetailClick, onBackOpen }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()

    const mapUrl = '/main-map'
    const listUrl = '/main-list'

    const dispatch = useDispatch()
    const location = useSelector((state: any) => state.locationDetail.value) as LocationWithChargers
    const locationExecuted = useSelector((state: any) => state.locationDetail.executed)
    // const chargingSession = useSelector((state: any) => state.chargingSession.value)

    useEffect(() => {
        if (!location || location.id !== locationId) {
            dispatch(fetchLocationDetail(locationId))
        }
    }, [dispatch, location, locationId])

    // const prepareFullAdress = (index: any) => {
    //     if (index === 0) {
    //         return ', ' + location.street + ' ' + location.houseNum + ', ' + location.postalCode
    //     } else {
    //         return ''
    //     }
    // }

    const toMap = () => {
        history.push(mapUrl)
    }

    const toList = () => {
        history.push(listUrl)
    }

    // const isDisabled =
    //     chargingSession != null && chargingSession.endedAt == null && chargingSession.location.id !== locationId

    // const locationFields = location
    //     ? [
    //           [t('locations.address'), location.city],
    //           [t('locations.description'), location.description],
    //           [t('locations.totalPower'), location.totalPower],
    //       ]
    //     : []

    return (
        <Grid item xs={12} className={classes.mainContainer}>
            <DetailHeader locationId={locationId} backClick={onBackOpen === 'list' ? toList : toMap} />
            {location && locationExecuted ? (
                <Grid item xs={12} className={classes.locationPreviewContainer}>
                    <Image
                        className={classes.chargerImage}
                        imageClassName={classes.chargerImage}
                        url={location.images.length > 0 ? location.images[0].url : undefined}
                        radius="0px"
                    />

                    <div className={classes.dataContainer}>
                        <Grid item xs={12} className={classes.wrapper}>
                            <Grid item xs={12} className={classes.wrapperInner}>
                                <Grid item xs={12} className={classes.topDecoration}>
                                    <div className={classes.rectangle} />
                                </Grid>
                                <Grid item xs={12} className={classes.headerContainer}>
                                    {location.name}
                                </Grid>
                                <Grid item container xs={12} className={classes.openTimeContainer}>
                                    <Grid item className={classes.openTimeIconContainer}>
                                        <WatchLater className={classes.openTimeIcon} />
                                    </Grid>
                                    <Grid item className={classes.openTime}>
                                        Open 24/7
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                    className={classes.controlPanelContainer}
                                >
                                    <Grid item>
                                        <Button
                                            className={classes.controlPanelLeftButton}
                                            onClick={() => {
                                                // this will redirect to google map in non-Apple enviroments
                                                window.open(
                                                    `https://maps.apple.com/?q=${location.latitude},${location.longitude}`,
                                                    '_blank'
                                                )
                                            }}
                                        >
                                            <Grid item className={classes.navigateIconContainer}>
                                                <img
                                                    alt="Navigation"
                                                    src={NavigationIcon}
                                                    className={classes.navigateIcon}
                                                />
                                            </Grid>
                                            <Grid item className={classes.navigateText}>
                                                {t('detail.navigate')}
                                            </Grid>
                                        </Button>
                                    </Grid>
                                    <Grid item></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            ) : (
                <CircularProgress />
            )}
        </Grid>
    )
}
