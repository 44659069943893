import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import DoneIcon from '@material-ui/icons/Done'
import { ChargingSession, LocationWithChargers } from '../../shared/types/location'
import { useSelector, useDispatch } from 'react-redux'
import { Charger, ChargerConnector, getConnectorLabel } from '../../shared/types/charger'
import moment from 'moment'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import { exportFile } from '../../shared/utils/export'
import { config, getToken } from '../../config'
import { setChargingSession } from '../../redux/actions/charging'
import { getOffset } from '../../shared/utils/localTime'
import { clearPaymentState } from '../../redux/actions/users'
import CarLoading from '../../static/car_loading.png'
import { BottomControlPanel } from '../../core/footer/bottomControlPanel'
import { consumedKwh } from '../../shared/utils/display'
import { useHistory } from 'react-router-dom'

const ERROR_CAUSE_CHARGINGOVERFLOW = 'chargingoverflow'
const ERROR_CAUSE_PAYMENTERROR = 'paymenterror'

const useStyles = makeStyles((theme) => ({
    scrollable: {
        zIndex: 3,
        overflowY: 'auto',
        padding: '23px 23px 23px 23px',
    },
    navigateButton: {
        maxWidth: '280px',
        marginTop: '20px',
        width: '100%',
        height: '60px',
        borderRadius: '14px',
        backgroundImage: 'linear-gradient(259deg, #268890 1%, #42c7d0 95%, #42c7d0 5%)',
    },

    downloadButton: {
        marginTop: '59px',
        maxWidth: '280px',
        width: '100%',
        height: '60px',
        borderRadius: '14px',
        border: 'solid 1px #37c0ca',
        background: 'transparent',
        '&:hover': {
            background: '#0b3134',
        },
    },
    navigateIcon: {
        marginRight: theme.spacing(1),
    },
    charging: {
        paddingBottom: theme.spacing(3),
        textAlign: 'left',
        '& .MuiLinearProgress-root': {
            height: theme.spacing(2),
        },
    },
    subTitle: {
        marginTop: '14px',
        fontFamily: "'Muli', sans-serif",
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#37c0ca',
    },
    title: {
        marginTop: '21px',
        fontFamily: "'Play', sans-serif",
        fontSize: '32px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: 'normal',
        color: 'white',
    },
    smallTitle: {
        paddingTop: theme.spacing(2),
        textAlign: 'left',
        fontSize: '1rem',
        color: theme.palette.secondary.dark,
    },
    property: {
        paddingBottom: theme.spacing(2),
        textAlign: 'left',
        fontSize: '1.2rem',
        color: '#000000',
    },
    subTitleDetail: {
        marginTop: '43px',
        fontFamily: "'Muli', sans-serif",
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: 'normal',
        color: 'white',
    },
    subTitleDetailItem: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    line: {
        borderLeft: 'solid 1px #2c2e2f',
        height: '45px',
        width: '6px',
    },
    carImageItem: {
        position: 'absolute',
        left: 'calc(41% - 160px)',
        overflow: 'hidden',
        height: '75%',
        bottom: '15%',
    },
    dataContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '59px',
    },
    dataContainerInner: {
        justifyContent: 'flex-end',
        position: 'relative',
        maxWidth: '700px',
    },
    dataItem: {
        color: 'white',

        borderRadius: '10px',
        backgroundImage: 'linear-gradient(to right, #363b3d, rgba(26, 30, 32, 0) 100%)',
    },
    dataItemInner: {
        margin: '35px 31px 40px 36px',
    },
    dataItemSeparator: {
        width: '100%',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderImageSource: 'linear-gradient(to left, rgba(104, 225, 231, 0), #37c0ca)',
        borderImageSlice: '1',
        marginBottom: '32px',
        marginTop: '20px',
    },
    dataText: {
        fontFamily: "'Play', sans-serif",
        fontSize: '58px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: 'normal',
        color: '#37c0ca',
    },
    dataTextWarning: {
        fontFamily: "'Play', sans-serif",
        fontSize: '58px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: 'normal',
        color: '#ff4e4e',
    },
    dataDescText: {
        marginTop: '5px',
        marginLeft: '10px',
        fontFamily: "'Play', sans-serif",
        fontSize: '20px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        color: 'white',
    },
    bottomDecoration: {
        bottom: '0px',
        left: '0px',
        zIndex: 0,
        position: 'absolute',
        width: '100%',
        height: '261px',
        backgroundImage: 'linear-gradient(to bottom, rgba(55, 192, 202, 0) 1%, rgba(55, 191, 201, 0.46))',
    },
    startTimeText: {
        opacity: 0.5,
        fontFamily: "'Muli', sans-serif",
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: 'normal',
        color: 'white',
        marginBottom: '14px',
    },
    startTimeDataContainer: {
        opacity: 0.5,
        borderRadius: '8px',
        backgroundColor: '#363b3d',
    },
    startTimeData: {
        margin: '9px 10px 9px 10px',
        fontFamily: "'Muli', sans-serif",
        fontSize: '12px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: 'normal',
        color: 'white',
    },
}))

interface ChargingDoneProps {
    location: LocationWithChargers
}

const errorInPayment = -1

export const ChargingDone: React.FC<ChargingDoneProps> = ({ location }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const chargingSession: ChargingSession = useSelector((state: any) => state.chargingSession.value)
    const currency = useSelector((state: any) => state.paymentSettings.value.currency)
    const paymentState = useSelector((state: any) => state.payment.paymentState)

    const [open, setOpen] = useState<boolean>(paymentState.value !== null && chargingSession.price > 0)

    const charger: Charger = chargingSession.charger
    const connector: ChargerConnector = chargingSession.chargingConnector

    const endTime = moment(chargingSession.endedAt)

    const onExport = () => {
        exportFile(config.proxy, config.api, `ChargingSession/invoice/${chargingSession.id}`, getToken(), getOffset())
    }

    const handleRedirectToCardRegister = () => {
        history.push('/register-card/map')
    }

    const renderFinishedChargingGrid = (chargingSession: ChargingSession) => {
        let error = chargingSession.errorCause ? chargingSession.errorCause.toLowerCase().split(' //')[0] : null

        switch (error) {
            case ERROR_CAUSE_CHARGINGOVERFLOW:
                return (
                    <Grid>
                        <Grid xs={12} container justify="center">
                            <Grid item className={classes.dataText}>
                                {t('charging.freeCharging1')}
                            </Grid>
                        </Grid>
                        <Grid xs={12} container justify="center" style={{ margin: '15px 0px' }}>
                            <Grid item className={classes.dataDescText} style={{ margin: '0px' }}>
                                {t('charging.freeCharging2')}
                            </Grid>
                        </Grid>
                        <Grid xs={12} container justify="center">
                            <Grid item className={classes.dataText} style={{ fontSize: '40px' }}>
                                {t('charging.freeCharging3')}
                            </Grid>
                        </Grid>
                    </Grid>
                )
            case ERROR_CAUSE_PAYMENTERROR:
                return (
                    <Grid>
                        <Grid xs={12} container justify="center">
                            <Grid item className={classes.dataTextWarning}>
                                {t('charging.paymentError1')}
                            </Grid>
                        </Grid>
                        <Grid xs={12} container justify="center" style={{ margin: '15px 0px' }}>
                            <Grid item className={classes.dataDescText} style={{ margin: '0px' }}>
                                {t('charging.paymentError2')}
                            </Grid>
                        </Grid>
                    </Grid>
                )           
            default:
                return (
                    <Grid>
                        <Grid xs={12} container justify="center">
                            <Grid item className={classes.dataText}>
                                {consumedKwh(chargingSession)}
                            </Grid>
                            <Grid item className={classes.dataDescText}>
                                kWh
                            </Grid>
                        </Grid>
                        <Grid xs={12} container justify="center">
                            <div className={classes.dataItemSeparator}></div>
                        </Grid>
                        <Grid xs={12} container justify="center">
                            <Grid item className={classes.dataText}>
                                {chargingSession.price}
                            </Grid>
                            <Grid item className={classes.dataDescText}>
                                {currency}
                            </Grid>
                        </Grid>
                    </Grid>
                )
        }
    }

    return (
        <>
            <div className={classes.scrollable}>
                <div style={{ zoom: '0.75' }}>
                    <Grid container direction="column">
                        {location && (
                            <>
                                <Grid item className={classes.subTitle}>
                                    {t('charging.chargedAt')}
                                </Grid>
                                <Grid item className={classes.title}>
                                    {location.name}
                                </Grid>

                                <Grid item className={classes.subTitleDetail}>
                                    <Grid xs={12} container justify="center">
                                        <Grid item xs={4} className={classes.subTitleDetailItem}>
                                            {charger?.name}
                                        </Grid>
                                        <Grid item xs={4} className={classes.subTitleDetailItem}>
                                            <div className={classes.line} />
                                        </Grid>
                                        <Grid item xs={4} className={classes.subTitleDetailItem}>
                                            {connector
                                                ? getConnectorLabel(t, connector.socketType, connector.externalId)
                                                : t('common.unknown')}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <div className={classes.dataContainer}>
                                    <Grid
                                        className={classes.dataContainerInner}
                                        container
                                        alignItems="flex-end"
                                        justify="space-around"
                                    >
                                        <img
                                            height={'100%'}
                                            alt="Car"
                                            src={CarLoading}
                                            className={classes.carImageItem}
                                        />

                                        <Grid item style={{ width: 'calc(50% - 235px)' }}></Grid>
                                        <Grid
                                            item
                                            className={classes.dataItem}
                                            style={{ width: '70%', maxWidth: '330px', minWidth: '225px' }}
                                            justify="flex-end"
                                        >
                                            <Grid item className={classes.dataItemInner}>
                                                {renderFinishedChargingGrid(chargingSession)}

                                                <Grid xs={12} container justify="center">
                                                    <div className={classes.dataItemSeparator}></div>
                                                </Grid>
                                                <Grid xs={12} container>
                                                    <div className={classes.startTimeText}>
                                                        {t('charging.finishedAt')}
                                                    </div>
                                                </Grid>

                                                <Grid container>
                                                    <Grid
                                                        item
                                                        className={classes.startTimeDataContainer}
                                                        style={{ marginBottom: '14px' }}
                                                    >
                                                        <div className={classes.startTimeData}>
                                                            {endTime.format('LT')}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                <Grid xs={12} container>
                                                    <div className={classes.startTimeText}>
                                                        {t('charging.chargingDuration')}
                                                    </div>
                                                </Grid>

                                                <Grid container>
                                                    <Grid item className={classes.startTimeDataContainer}>
                                                        <div className={classes.startTimeData}>
                                                            {chargingSession.length + t('common.minutes')}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </>
                        )}
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Button
                            className={classes.downloadButton}
                            onClick={onExport}
                            disabled={!chargingSession.hasInvoice}
                        >
                            <PictureAsPdfIcon style={{ color: '#37c0ca' }} />
                        </Button>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Button
                            component={RouterLink}
                            to={`/main-map`}
                            size="large"
                            variant="contained"
                            color="primary"
                            className={classes.navigateButton}
                            onClick={() => dispatch(setChargingSession(null))}
                        >
                            <DoneIcon className={classes.navigateIcon} />
                            {t('common.done')}
                        </Button>
                    </Grid>

                    <div style={{ height: '143px' }} />
                </div>
                <BottomControlPanel actualPage="chargingDone" positionAbsolute={true} />
            </div>
            <div className={classes.bottomDecoration} />
            {
                <Dialog
                    open={open}
                    onClose={() => {
                        dispatch(clearPaymentState)
                    }}
                >
                    {paymentState.value === errorInPayment ? (
                        <>
                            <DialogTitle>{t('card.error')}</DialogTitle>
                            <DialogContent>
                                <Typography>{t('card.gatewayError')}</Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        dispatch(clearPaymentState)
                                        dispatch(setChargingSession(null))
                                        setOpen(false)
                                        handleRedirectToCardRegister()
                                    }}
                                    variant="contained"
                                    color="primary"
                                >
                                    {t('common.continue')}
                                </Button>
                            </DialogActions>
                        </>
                    ) : (
                        <>
                            <DialogTitle>{t('card.success')}</DialogTitle>
                            <DialogContent>
                                <Typography>
                                    {t('card.wereCharged', { amount: chargingSession.price, currency: currency })}
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpen(false)} variant="contained" color="primary">
                                    {t('common.close')}
                                </Button>
                            </DialogActions>
                        </>
                    )}
                </Dialog>
            }
        </>
    )
}
